/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-lot-lite-card::v-deep {
  max-width: 500px;
}
.drone-lot-lite-card::v-deep .ant-card-body {
  padding: 16px 16px;
}
body[data-device=mobile] .drone-lot-lite-card::v-deep .mycard-content {
  flex-wrap: wrap;
}
body[data-device=mobile] .drone-lot-lite-card::v-deep .lot-detail-pane {
  margin-left: 0 !important;
  padding-left: 8px !important;
  border-left: none;
  border-top: 2px dashed #d9d9d9;
  margin-top: 12px;
  padding-top: 8px;
}
.lot-detail-pane {
  border-left: 2px dashed #d9d9d9;
  margin-left: 8px !important;
  padding-left: 12px  !important;
  width: 150px;
}
