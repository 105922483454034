/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-steps::v-deep .ant-steps-item-title {
  font-size: 14px;
}
.drone-steps::v-deep .ant-steps-item-description {
  font-size: 12px;
}
body[data-device=mobile] .drone-steps::v-deep .ant-steps-item-title {
  font-size: 12px;
}
body[data-device=mobile] .drone-steps::v-deep .ant-steps-item-description {
  display: none;
}
.drone-steps-content::v-deep {
  margin: 24px auto 0;
  padding: 16px 32px;
  background: #fff;
}
.drone-steps-content::v-deep .step-title {
  color: #14837B;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 600;
}
.drone-steps-content::v-deep .step-description {
  color: #8c8c8c;
  font-size: 1em;
  margin-bottom: 32px;
}
body[data-device=mobile] .drone-steps-content::v-deep {
  padding: 16px;
}
