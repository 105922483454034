/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-lot-card {
  margin: 0 auto 16px;
}
.success-pane::v-deep {
  border: 2px solid #52c41a;
  border-radius: 24px;
  text-align: center;
  padding: 32px 32px 24px;
  max-width: 800px;
  margin: 0 auto 32px;
}
.success-pane::v-deep .success-pane-icon {
  font-size: 128px;
  color: #52c41a;
  margin-bottom: 12px;
}
.success-pane::v-deep .success-pane-message {
  font-size: 28px;
  font-weight: 600;
  color: #52c41a;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 32px;
}
.success-pane::v-deep .success-pane-content {
  text-align: left;
  margin: auto;
}
body[data-device=mobile] .success-pane::v-deep .success-pane-icon {
  font-size: 96px;
  margin-bottom: 16px;
}
body[data-device=mobile] .success-pane::v-deep .success-pane-message {
  font-size: 24px;
  font-weight: 600;
  color: #52c41a;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 24px;
}
body[data-device=mobile] .success-pane::v-deep .content-label {
  width: 100px !important;
}
.next-action .next-action-title {
  font-size: 24px;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 8px;
}
.next-action .next-action-list {
  padding-left: 48px;
  list-style: circle;
}
.next-action .next-action-list li {
  font-size: 1.1em;
  margin-bottom: 8px;
}
