/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@media (max-width: 991px) {
  .content-row.lot-row .content-label {
    display: none;
  }
  .content-row.lot-row .content-value {
    width: 100%;
    margin: 0 auto 12px;
    float: none;
  }
  .content-row.lot-row .content-value .drone-lot-lite-card {
    margin: 0 auto;
  }
}
.step3-confirm-display {
  text-align: right;
  margin-top: 32px;
}
.content-section {
  margin-bottom: 24px;
}
.drone-info-section {
  margin: 0 auto 32px;
  max-width: 1024px;
  padding: 24px 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
